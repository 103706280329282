<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">CBP 1.0 </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">Mot de passe oublié? 🔒 </b-card-title>
          <b-card-text class="mb-2">
            Entrez votre email et nous vous enverrons des instructions pour réinitialiser votre
            mot de passe
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
              <b-form-group label="Email" label-for="forgot-password-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="forgot-password-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="forgot-password-email" placeholder="Email ici..." />
                  <small class="text-danger" v-if="errors.length > 0 ? true : false">Compléter votre email est requis
                    ici</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="primary" block :disabled="loading">
                <LoadingIcon v-if="loading" style="width: 24px; float: center"></LoadingIcon>

                <span v-if="loading == false">Envoyer lien de réinitialisation</span>

              </b-button>

            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Rétour vers connexion
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Logo from "@core/layouts/components/Logo.vue";
import LoadingIcon from "@core/layouts/components/Loading.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { $themeConfig } from "@themeConfig";
import axios from "axios";

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    LoadingIcon,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
      loading: false,
      LoadingIcon,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {

        this.loading = true;

        if (success) {

          let formData = new FormData();
          formData.append("email", this.userEmail);

          axios
            .post($themeConfig.app.api_endpoint + `auth/password-reset`, formData)
            .then((response) => {

              this.loading = false;

              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Envoie Email Réussi",
                    text: "Vérifier votre adresse email pour la réinitialisation.",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });

                // Redirect to reset password page
                this.$router.push({ name: "auth-refresh" });

              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Erreur Survenue.",
                    text: response.data.message,
                    icon: "InfoIcon",
                    variant: "warning",
                  },
                });
              }
            })
            .catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Erreur Exceptionnelle Survenue.",
                  text: error.response.data.message,
                  icon: "InfoIcon",
                  variant: "warning",
                },
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
